<template>
  <div class="content-body">
    <router-view/>
  </div>
</template>
<script>
    export default {
        name: 'content_body'
    }
</script>
<style>

</style>